@media (max-width: 768px) {
  .swiper-slide {
    width: 200px;
    padding: 5px;
    h2{
      font-size: 1.2rem;
    }
    p{
      font-size: .8rem;
    }
  }
}
