.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
  display: none;
  width: 100%;
  height: 100%;
  background: $black-2;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: -320px;
  z-index: 991;
  display: block;
  max-width: calc(100% - 80px);
  width: 300px;
  height: 100%;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 8px 0 24px;
  color: $text;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &__logo {
    padding: 30px;
    height: 140px;
    border-bottom: 1px solid $line;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.is-active {
    left: 0;
  }

  &__list {}

  &__item {}

  &__link {
    @extend .flex;
    @extend .effect;
    padding: 12px;
    font-weight: 600;

    .bi {
      transition: transform 0.3s;
    }

    &.is-active,
    &:Hover {
      color: $primary;
    }

    &.is-active {
      .bi {
        transform: rotate(-180deg);
      }
    }
  }

  &__item--has-sub {}
}

.mobile-menu-tabs {
  height: 100%;

  &__panel {
    overflow-y: auto;
    padding: 15px 0;
    height: calc(100% - 190px);
  }
}

.mobile-tab-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  z-index: 4;

  &__link {
    @extend .effect;
    display: inline-flex;
    flex: 1px;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-top: 1px solid $line;
    text-align: center;
    font-weight: 600;
    font-size: 0.8rem;
    gap: 4px;

    .bi {
      width: 14px;
      color: $primary;
    }

    &.active,
    &:hover {
      border-color: $primary;
    }
  }
}

.mobile-sub-menu {
  display: none;
  padding: 15px 25px;
  background: #fafafa;

  &__item {}

  &__link {
    @extend .effect;
    display: block;
    padding: 4px 0;
    font-size: 0.9rem;

    &.is-active,
    &:hover {
      color: $primary;
    }
  }
}
