.title {
  font-size: 3rem;
  display: block;
  font-family: "Cormorant", serif;

  &--big {
    font-size: 48px;
  }

  &--semi {
    font-size: 26px;
  }

  &--medium {
    font-size: 22px;
  }

  &--smaller {
    font-size: 1.5rem;
  }
}
