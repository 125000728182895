@import "reset", "colors", "mixin", "global", "hamburger/hamburgers", "bootstrap", "components/swiper", "typography", "components/buttons";

.btn-mobile {
  position: relative;
  top: 3px;
  padding: 0;
}

#__bs_notify__ {
  display: none !important;
}

.mobil-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $black;

  &.active {
    display: block;
  }
}

.swiper-slide {
  padding: 15px;
  width: 400px;
  height: auto;
  text-align: center;
  transition: background 0.3s;

  a {
    display: block;
  }

  &.divider {
    padding: 0;
    width: 1px;
    background-color: $white;
  }

  img {
    margin-bottom: 15px;
  }

  h2 {
    display: block;
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-family: "Cormorant", serif;
  }

  p {
    display: block;
    margin-bottom: 25px;
    font-size: 0.9rem;
    font-family: "Raleway", sans-serif;
    opacity: 0.7;
  }

  &:hover {
    background-color: $white;
  }
}

.button {
  font-family: "Cormorant", serif;
}

.container-fluid {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100vh;
}

.wrapper {
  max-width: 100%;
}

.cover {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 20px;
  height: 100%;
}

.left-cover {
  left: 0;
  background: linear-gradient(to right, #1d262d, transparent);
}

.right-cover {
  right: 0;
  background: linear-gradient(to left, #1d262d, transparent);
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  gap: 15px;

  &__link {
    @extend .effect;
    display: inline-block;
    color: rgba(#ffffff, 0.9);

    &:hover {
      color: #a39383;
    }
  }
}
@import "responsive/style1600", "responsive/style1400", "responsive/style1200", "components/mobile-nav", "responsive/style992", "responsive/style768", "responsive/style576";
