@media (max-width: 992px) {
  .btn-mobile{
    display: inline-block;
  }

  .swiper-slide{
    width: 300px;
  }

  .title{
    font-size: 2rem;
  }

  .mb-5{
    margin-bottom: 30px !important;
  }
}
