$raleway: "Open Sans", sans-serif;

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  background: #1D252C;
  color: #fff;
  font-family: $raleway;
  line-height: 1.5;
}

iframe,
img,
svg,
video {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

button,
input,
select,
textarea {
  font-family: $raleway;
}

button {
  background: none;
  border: none;
  outline: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.bi {
  position: relative;
  top: -1px;
}

.noscroll {
  overflow: hidden;
}

.tab-panel {
  display: none;
}

.tab-active {
  display: block;
  animation: fadeIn 0.5s;
}

.tabcontent {
  animation: fadeIn 0.5s;
}

.fadeIn {
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
